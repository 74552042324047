import React, { useEffect } from "react";

const UnAuthorized = (props) => {
  useEffect(() => {
    if (!props.location.state) {
      props.navigate("/");
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="content-wrapper custom-home-wrapper">
          <div className="content pt-0">
            <div className="flex-fill">
              <div className="text-center mb-3">
                <img
                  src="/global_assets/images/icons-svg/logo.svg"
                  className="mb-2 w-25"
                  alt=""
                />
                <h4 className="mb-1">
                  You do not have access to the requested page.
                </h4>
                <small className="w-50 mx-auto text-muted">
                  You are unable to access this page as you do not have the
                  required permissions.
                  <br />
                  If you believe you should have access to this page please
                  contact administrator support.
                </small>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="d-flex justify-content-center">
                  <a
                    href="/"
                    className="btn btn-primary mt-3 mt-sm-0  btn-block mr-2"
                  >
                    <em className="icon-user-lock mr-2"></em> My Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UnAuthorized;
