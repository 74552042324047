import { CssBaseline } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import LoginLayout from "../components/layouts/LoginLayout";
import ResetPassword from "../components/resetPassword/ResetPassword";

const ResetPasswordPage = () => {
  const { state } = useLocation();
  //const purpose = state?.purpose ? state.purpose : "";
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout>
        <ResetPassword state={state} />
      </LoginLayout>
    </React.Fragment>
  );
};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
