import React, { useState, useEffect, useContext } from 'react';
import Layout from '../components/layouts/Layout';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { LocalContext } from "../LocalProvider";

// For Processing Payment Response
import queryString from 'query-string';
import { updateInvoiceTransaction } from "../services/invoices.service";


const StyledContainer = styled.div`
    width: 95%;
    height: fit-content;
    background: white;
    margin: auto;
    border-radius: 3px;
`
const StyledLogo = styled.a`
    & img{
      width: 70%;
      object-fit: contain;
    }
`

const StyledBox = styled.div`
    height: 225px;
    width: 50%;
    background-color:white;
    margin-bottom: 20px;
    display: flex;
    padding:1em;
    flex-direction: column;
    align-items: center;
    background: #F5FFF3 0% 0% no-repeat padding-box;
    border: 1px solid #11D301;
    justify-content: center;
    @media (max-width: 1024px){
        width: 70%;
        height: 50%;
    }
    @media (max-width:798px){
        width: 90%;
        height: 60%;
        tex-align: center;
    }
`
const StyledButton = styled(Button)`
    width: 330px;
    height:48px;
    border-radius:0px;
    @media (max-width: 1024px){
        width: 50%;
    }
    @media (max-width:798px){
        width: 90%;
    }
    
`
const StyledInfo = styled.div`
    margin-top:10px;
    text-align: center;
    box-sizing: content-box;
    & span{
        color: #1976d2;
        font-weight: bold;
    }
    @media (max-width: 798px){
        margin-top: 15px;
    }
`

const ConfirmationPage = (props) => {
    const {translate} = useContext(LocalContext);
    const [paymentData, setPaymentData] = useState({});


    const redirectToDashboard = () => {
        window.location.href="/";
    }

    // Process Query Params and Log Sucessful Payment to Pimcore
    useEffect(() => {
        const processPaymentUpdate = async () => {
            let params = queryString.parse(window.location.search);
            console.log(params);
            setPaymentData(params);
            console.log(paymentData);
            let invoiceParams = {
                arTransactionId: params['CONCEPTO'],
                bankTransactionId: params['AUTORIZA'],
                paidAmount: params['IMPORTE'],
                bankName: 'CITI',
                siteId: 5 // TODO: Make Dynamic
            };
            invoiceParams['queryString'] = QueryStringFromJSON(invoiceParams);
            
            console.log(invoiceParams);
            let resp = await updateInvoiceTransaction(invoiceParams).then((res) => {
                console.log(res);
                return res;
            });
            console.log(resp);
        };
        processPaymentUpdate();
    }, []);
    


    /**
     * HELPER FUNCTION
     * ----------------
     * Creates a query string from a JSON
     * 
     */
     function QueryStringFromJSON(json) {            
        return '?' + 
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        }).join('&');
    }
    
    return (
        <Layout>
            <StyledContainer >
                <AppBar position="static" sx={{ backgroundColor: 'white', marginBottom: '10px', height: '90px', justifyContent: 'center' }}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            >
                                <StyledLogo href="#">
                                    <img src='/icons/pepsico_logo.png' alt="PEPSICO" />
                                </StyledLogo>
                            </Typography>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                            >
                                <StyledLogo href="#">
                                    <img src='/icons/pepsico_logo.png' alt="PEPSICO" />
                                </StyledLogo>
                            </Typography>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 100px)', flexDirection: 'column' }}>
                    <StyledBox >
                        <div style={{ color: '#11D301', border: '1px solid #11D301', borderRadius: '50%', height: '60px', width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor:'#11D301', color:'white' }}>
                            <ThumbUpAltIcon fontSize='large' />
                        </div>
                        <StyledInfo >
                            <strong>{translate("Thank you for payment of:")}</strong> <span>${paymentData.IMPORTE}</span>
                            <p>{translate("Your payment will be validated and applied in approximately 48 hours.")}</p>
                        </StyledInfo>
                        <p>{translate("Payment Reference Number")} <strong>{paymentData.AUTORIZA}</strong></p>
                    </StyledBox>
                    <StyledButton onClick={() => redirectToDashboard()} variant="contained">{translate("Back To AR Portal")}</StyledButton>
                </Box>
            </StyledContainer>
        </Layout>
    )
}

export default ConfirmationPage;