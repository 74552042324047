import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingHOC from "../../components/common/loader/LoadingHOC.component";
import { SignInButton, useStyles } from "../../components/login/Login.styles";
import { LocalContext } from "../../LocalProvider";
import {
  validateProfile,
  verifyOTP,
} from "../../services/registration.services";

const VerifyCode = (props) => {
  const { setLoading } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const { translate } = useContext(LocalContext);
  const [isDisable, setIsDisable] = useState(true);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [otp, setOtp] = useState("");
  const userData = state?.userData;

  const handleChange = () => (event) => {
    setOtp(event.target.value);
    if (event.target.value.length === 6) setIsDisable(false);
  };

  const confirmOtp = () => {
    setLoading(true);
    const profileData = {
      userId: userData?.userId ? userData.userId : "",
      erpID: userData?.erpID ? userData.erpID : "",
      code: otp,
    };
    verifyOTP(profileData).then((res) => {
      if (res?.success) {
        setLoading(false);
        navigate("/reset-password", {
          state: {
            purpose: "account",
            userData: userData,
          },
        });
      } else {
        handleMsgStatus(translate(res.msg));
      }
    });
  };

  const resendEmail = () => {
    setLoading(true);
    const profileData = {
      erpID: userData?.erpID,
      userId: userData?.userId,
      userIdType: "Email",
    };
    validateProfile(profileData).then((res) => {
      if (res.success) {
        handleMsgStatus(translate(res.msg));
        setIsSuccess(true);
      } else {
        handleMsgStatus(translate(res.msg));
      }
    });
  };

  const handleMsgStatus = (res) => {
    setIsSuccess(false);
    setNotificationMsg(res);
    setOtp("");
    setLoading(false);
  };

  return (
    <Grid item md={12} xs={12} sx={{ height: "410px" }}>
      <form
        className={classes.root}
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h2 className={classes.signInTitle}>
              {translate("Create an Account")}
            </h2>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "0.9rem" }}>
              {translate(
                "We sent an email to your email address. Please click the link the email to validate."
              )}
            </Typography>
          </Grid>
          {notificationMsg && (
            <Grid item xs={12} sx={{ mb: "0.5rem" }}>
              <strong
                className={
                  isSuccess ? classes.successMsg : classes.passwordNotification
                }
              >
                {notificationMsg}
              </strong>
            </Grid>
          )}
          <Grid item xs={12} sx={{ mb: "2rem" }}>
            <FormControl className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "bold" }}
              >
                {translate("OTP")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={otp}
                label="OTP"
                onChange={handleChange()}
                className={classes.inputBox}
                type="number"
                autoFocus
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <SignInButton disabled={isDisable} onClick={confirmOtp}>
              {translate("Confirm")}
            </SignInButton>
          </Grid>
          <Grid item xs={12}>
            <SignInButton onClick={resendEmail}>
              {translate("Re-Send the Email")}
            </SignInButton>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider sx={{ m: 0, border: "none !important" }}>
              {translate("or")}
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <SignInButton
              disabled={isDisable}
              variant="outlined"
              onClick={() => navigate("/create-account")}
            >
              <strong>{translate("Go Back")}</strong>
            </SignInButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

VerifyCode.propTypes = {};

export default LoadingHOC(VerifyCode);
