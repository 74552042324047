import { CssBaseline } from "@mui/material";
import React from "react";
import LoginLayout from "../components/layouts/LoginLayout";
import ForgotPasswordComponent from "../components/login/ForgotPassword.component";

const ForgotPassword = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout>
        <ForgotPasswordComponent />
      </LoginLayout>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
