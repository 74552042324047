import SingleColumn from "../components/styled/SingleColumn.styled";

const healthJson = {
  status: "healthy",
  description: "App is healthy",
};

const Health = (props) => {
  return <SingleColumn>{JSON.stringify(healthJson)}</SingleColumn>;
};

export default Health;
