import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignInButton, useStyles } from "../../components/login/Login.styles";
import { LocalContext } from "../../LocalProvider";

const EmailVerification = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { translate } = useContext(LocalContext);
  const [isDisable, setIsDisable] = useState(false);

  return (
    <Grid item md={12} xs={12} sx={{ height: "410px" }}>
      <form className={classes.root} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h2 className={classes.signInTitle}>
              {translate("Create an Account")}
            </h2>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "0.9rem" }}>
              {translate("We sent an email to your email address. Please click the link the email to validate.")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SignInButton disabled={isDisable}>
              {translate("Re-Send the Email").toUpperCase()}
            </SignInButton>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider sx={{ m: 0, border: "none !important" }}>
              {translate("or").toUpperCase()}
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <SignInButton
              disabled={isDisable}
              variant="outlined"
              onClick={() => navigate("/create-account")}
            >
              <strong>{translate("Go Back")}</strong>
            </SignInButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

EmailVerification.propTypes = {};

export default EmailVerification;
