import API_ROUTE from '../constants/API_ROUTE';
import { apiHeaderData } from "../utils/urls";
const INVOICES_JSON = '/invoice-list.json';
const PAYMENT_HISTORY = '/payment-history.json';


function GetInvoices(customerId) {
    return fetch(
        //ROOT_INVOICES_JSON
        API_ROUTE.GET_INVOICES + '?customerId=' + customerId, {
        method: "GET",
        headers: apiHeaderData,
    }
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                // const statusCode = resp.statusCode;
                // if (statusCode === 200) {
                return { invoices: resp.invoices, statistics: resp.totals };

                // } else {
                //     return { error: "Server error: please contact your administrator" };
                // }
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("Invoice API service interrupted --> " + err);
        });
}

function GetInvoiceDetail(invoiceNumber) {
    return fetch(
        API_ROUTE.GET_INVOICES + "?invoiceNumber=" + invoiceNumber, {
        method: "GET",
        headers: apiHeaderData,
    }
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                return { invoices: resp.invoices };
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("Invoice API service interrupted --> " + err);
        });
}


function GetAllInvoices() {
    return fetch(
        INVOICES_JSON
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                // const statusCode = resp.statusCode;
                // if (statusCode === 200) {
                return { invoices: resp };

                // } else {
                //     return { error: "Server error: please contact your administrator" };
                // }
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("Invoice API service interrupted --> " + err);
        });
}

function GetPaymentHistory() {
    return fetch(
        PAYMENT_HISTORY
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                // const statusCode = resp.statusCode;
                // if (statusCode === 200) {
                return { PaymentHistory: resp };

                // } else {
                //     return { error: "Server error: please contact your administrator" };
                // }
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("Payment History API service interrupted --> " + err);
        });
}


function getBankList() {
    return fetch(
        //ROOT_INVOICES_JSON
        API_ROUTE.GET_BANKLIST, {
        method: "GET",
        headers: apiHeaderData,
    }
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                return resp;
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("banklist api service interrupted --> " + err);
        });
}


function updateInvoiceTransaction(params) {
    return fetch(
        API_ROUTE.UPDATE_TRANSACTION+params['queryString'],
        {
            headers: apiHeaderData
        })
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                return resp;
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("banklist api service interrupted --> " + err);
        });
}



function postInvoices(params) {
    return fetch(
        //ROOT_INVOICES_JSON
        API_ROUTE.POST_INVOICES, {
        method: "POST",
        headers: apiHeaderData,
        body: JSON.stringify(params)
    }
    )
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp) {
                return resp;
            }
        }, (err) => {
            console.log(err.response);
        })
        .catch((err) => {
            console.log("banklist api service interrupted --> " + err);
        });
}

export { 
    GetInvoices,
    GetAllInvoices,
    GetPaymentHistory,
    GetInvoiceDetail,
    getBankList,
    postInvoices,
    updateInvoiceTransaction,
 }
