import { createContext, useState } from "react";
import { useEffect } from "react";
import API_ROUTE from "./constants/API_ROUTE";

export const LocalContext = createContext();

function LocalProvider({ children }) {
  const getTrans = JSON.parse(localStorage.getItem("translationdata"));
  const [contentLan, setContentLan] = useState({});
  const [selectedLocaleLang, setSelectedLocalelang] = useState('ES');




  useEffect(() => {
    const GetTranslationAPIList = () => {
      if (!localStorage.getItem("language") || !localStorage.getItem("translationdata")){
        return fetch(API_ROUTE.GET_TRASLATIONLIST, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            siteId: 5,
          },
        })
          .then((resp) => resp.json())
          .then(
            (resp) => {
              if (resp) {
                localStorage.setItem("translationdata", JSON.stringify(resp));
                if (!localStorage.getItem("language")) {
                  localStorage.setItem("language", "ES");
                  localStorage.setItem("translationdata", JSON.stringify(resp)); // ES will be default language selector as discussed in Meeting.
                  setSelectedLocalelang('ES');
                }
                else{
                  setSelectedLocalelang(localStorage.getItem("language"));
                }
                setContentLan({...resp})
              }
            },
            (err) => {
              console.log(err.response);
            }
          )
          .catch((err) => {
            console.log("Translation api service interrupted --> " + err);
          });
      }
      else {
          setSelectedLocalelang(localStorage.getItem("language"));
          setContentLan({...getTrans});
      }
    };
    
    GetTranslationAPIList();
  }, [])

  const handleLanguageChange = (lan) => {
    localStorage.setItem("language", lan);
    setSelectedLocalelang(lan);
  }

  const translate = (text) => {
    if (contentLan){
      if (!contentLan[text]) {
        return text;
      }
    } else {
      return text;
    }
    if (selectedLocaleLang) {
      return contentLan[text][selectedLocaleLang.toLowerCase()];
    } else {
      return text;
    }
  };

  return (
    <LocalContext.Provider value={{ translate, handleLanguageChange, selectedLocaleLang }}>
      {children}
    </LocalContext.Provider>
  );
}

LocalProvider.propTypes = {};

export default LocalProvider;
