import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APP_ROUTE from "../../constants/APP_ROUTE";
import { LocalContext } from "../../LocalProvider.js";
import LoadingHOC from "../common/loader/LoadingHOC.component";
import LanguageSelector from "../reusable/LanguageSelector";
import { SignInButton, useStyles } from "./Login.styles";

const Login = (props) => {
  const navigate = useNavigate();
  const { translate } = useContext(LocalContext);
  const { authState, oktaAuth } = useOktaAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const { setLoading } = props;

  const classes = useStyles();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  resetTranslationCache();

  useEffect(() => {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email) 
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [values]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [authState]);

  /* TODO: Move API Calls to authentication */
  const signInClose = (event) => {
    setLoading(true);
    event.preventDefault();
    const payload = {
      username: process.env.REACT_APP_LOGIN_PREFIX + `${values.email}`,
      password: values.password,
    };
    const validateCredential = oktaAuth.signInWithCredentials(payload);
    validateCredential
      .then((res) => {
        if (res.status === "SUCCESS") {
          setLoading(false);
          oktaAuth.signInWithRedirect({
            sessionToken: res.sessionToken,
            originalUri: APP_ROUTE.APP_DASHBOARD,
          });
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        setErrorMsg(err.errorSummary);
        setLoading(false);
        console.log("err", err.errorSummary);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onForgetPassword = () => {
    navigate("/forgot-password");
  };

  /**
   * Reset the translation data every time login page hits
   * 
   */
  function resetTranslationCache() {
    localStorage.removeItem("translationdata"); 
  }

  return (
    <Grid item md={12} xs={12} sx={{ height: "410px" }}>
      <form className={classes.root} noValidate>
        <Grid container direction="row">
          <Grid item md={12} xs={12}>
            <h2 className={classes.signInTitle}>{translate("Sign In")}</h2>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid
            item
            md={10}
            xs={10}
            sx={{ display: "flex" }}
            alignItems="center"
          >
            {errorMsg && (
              <strong className={classes.passwordNotification}>
                {translate(errorMsg)}
              </strong>
            )}
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={{ display: "flex" }}
          >
            <LanguageSelector />
          </Grid>
          <Grid item xs={12} sx={{ mb: "2rem" }}>
            <FormControl className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "500" }}
              >
                {translate("Email")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={values.email}
                label={translate("Email")}
                onChange={handleChange("email")}
                className={classes.inputBox}
                type="email"
                autoFocus
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{  fontWeight: "500" }}
              >
                {translate("Password")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined-password"
                value={values.password}
                label={translate("Password")}
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                className={classes.inputBox}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12} textAlign="right">
            <Button onClick={onForgetPassword}>
              <strong> {translate("Forgot Password?")}</strong>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SignInButton
              onClick={signInClose}
              className={classes.gridMargin}
              disabled={isDisable}
            >
              {translate("Log In")}
            </SignInButton>
          </Grid>

          <Grid item xs={12} className={classes.signUpLink}>
            <p>{translate("Not registered yet?")}</p>
            <Button sx={{}} onClick={() => navigate("/create-account")}>
              <strong>{translate("Create an Account")}</strong>
            </Button>
            <br></br>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

Login.propTypes = {};

export default LoadingHOC(Login);
