import React, { useContext, useState } from "react";
import { Grid, Typography, Card, CardContent, Link } from "@mui/material";
import { SignInButton, useStyles } from "../login/Login.styles";
import { LocalContext } from "../../LocalProvider";
import { useNavigate } from "react-router-dom";

const PasswordChangedComponent = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { translate } = useContext(LocalContext);
  const [isDisable, setIsDisable] = useState(false);

  return (
    <Grid item md={12} xs={12} sx={{ mb: "2rem", ml: "1rem" }}>
      <form className={classes.root} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h2 className={classes.signInTitle}>
              {translate("Here are your password reset instructions.")}
            </h2>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
              A request to reset your password for the{" "}
              <b>PepsiCo Mexico Payment Portal</b> has been made. If you don't
              make this request, simply ignore the email. If you did make this
              request, Please reset your password:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SignInButton
              disabled={isDisable}
              expand
              onClick={() =>
                navigate("/reset-password", {
                  state: {
                    purpose: "account",
                  },
                })
              }
            >
              {translate("Reset Password").toUpperCase()}
            </SignInButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

PasswordChangedComponent.propTypes = {};

export default PasswordChangedComponent;
