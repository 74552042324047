import React, {useContext} from 'react';
import { LocalContext } from "../LocalProvider";

const PageNotFound = (props) => {
  const {translate} = useContext(LocalContext);

  function handleGoToHome() {
    props.navigate("/")
  };


    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          background: "",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "auto",
            width: "100%",
            background: "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              height: "auto",
              width: "100%",
              background: "",
              marginTop: 30,
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "#50c499" }}> {translate("404 - PAGE NOT FOUND")}</h1>
            <button
              onClick={handleGoToHome}
              style={{
                height: 45,
                width: 200,
                borderRadius: 50,
                background: "#32c1b9",
                marginTop: 20,
                border: "1px solid transparent",
                color: "#fff",
                cursor: "pointer",
                textTransform: "uppercase",
              }}
              variant="contained"
              color="primary"
            >
              {translate("go to homepage")} 
            </button>
          </div>
        </div>
      </div>
    );
  
}

export default PageNotFound;
