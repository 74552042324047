import RootRouter from "./router/RootRouter";
import GlobalStyles from "./styles/global-styles";

import "./styles/build/style-ar.css";

function App() {
  
  return (
    <>
      <GlobalStyles/>
      <RootRouter />
    </>
      
  );
}

export default App;
