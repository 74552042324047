import { CssBaseline } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import LoginLayout from "../../components/layouts/LoginLayout";

const Registration = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout>
        <Outlet />
      </LoginLayout>
    </React.Fragment>
  );
};

export default Registration;
