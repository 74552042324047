import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";

export const SignInButton = styled.button`
  width: ${(props) => (props.expand ? "35%" : "100%")};
  background-color: ${(props) =>
    props.disabled || props.variant ? "#fff" : "#0076B3"};
  color: ${(props) =>
    props.disabled ? "#c4c4c4" : props.variant ? "#0076B3" : "#fff"};
  padding: 0.5em 0em;
  border: ${(props) =>
    props.disabled
      ? `1px solid #c4c4c4`
      : props.variant
      ? `2px solid #0076B3`
      : "none"};
  font-size: 18px;
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  &:hover {
    background-color: "#063F5E";
    color: "#c7c7c7";
  }
  & > strong {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
    width: 100%;
  }
`;

export const WhiteButton = styled.button`
  background-color: white;
  color: #0076b3 !important;
  padding: 0.5em 4.8em;
  border-color: #0076b3 !important;
  font-size: 1.2em;
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  &:hover {
    background-color: "#063F5E";
    color: "#c7c7c7";
  }

  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
    width: 100%;
  }
`;
export const Container = styled.div`
  //padding: 30px;
`;

export const useStyles = makeStyles(() => ({
  root: {
    lineHeight: 0.5,
    "& label.MuiInputLabel-root": {
      color: "#727272",
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldSet.MuiOutlinedInput-notchedOutline": {
      borderColor: "#727272",
    },
  },
  formControl: {
    "& div:before, div.MuiInput-underline:hover:before, div:after": {
      borderBottom: "none",
    },
    "& div.MuiSelect-select.MuiInput-input:focus": {
      background: "none",
    },
  },
  signInTitle: {
    paddingLeft: "1.75 em",
    marginLeft: "-1.625 em",
    marginBottom: "1.25 em",
  },
  signUpLink: {
    paddingTop: "0 !important",
    "& .MuiButton-root": {
      marginLeft: "0.5rem",
      // float: 'right',
      // top: '10px'
    },
    "& p": {
      display: "inline-block",
    },
  },
  inputBox: {
    borderRadius: "0",
    "& input.MuiInputBase-input": {
      padding: "13.5px 14px",
    },
    "& fieldset": {
      top: "0px",
      border: "1px solid #707070",
      borderRadius: "0",
      "&:hover": {
        border: "1px solid red",
      },
    },
  },
  gridMargin: {
    marginBottom: "0.625em",
    marginTop: "1.25em",
    width: "100%",
  },
  clickHereStyle: {
    color: "##0076B3",
    opacity: 1,
  },
  dialogStyle: {
    overflow: "hidden",
  },
  BtnBlueBG: {
    borderRadius: "0",
    height: "100%",
    width: "100%",
    "&:active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.active": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "#0076B3 !important",
      "& svg": {
        fill: "#fff",
      },
    },
  },
  paper: {
    maxWidth: "46.125em",
    height: "auto",
    overflow: "hidden",
    background: "#f7f7f7",
  },
  mobileCenter: {
    // position: "relative",
    height: "100%",
    width: "400px",
  },
  drinkImage: {
    height: "16.6875em",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
  },
  closeButton: {
    position: "absolute",
    backgroundColor: "#lightgray",
  },
  passwordNotification: {
    fontSize: "0.625rem",
    color: "red",
    margin: "0.25rem 0 0 0",
    position: 'relative',
    bottom: '2em',
    "& p": {
      fontSize: "0.625rem",
      margin: "0",
    },
  },
  errorMsg: {
    backgroundColor: "#D9534F",
    marginBottom: "1rem !important",
    "& .MuiTypography-root": {
      fontSize: "0.8rem",
      color: "#fff",
      padding: "0 2rem 1rem 1rem",
      "& a": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },
  successMsg: {
    fontSize: "0.625rem",
    color: "green",
    margin: "0.25rem 0 0 0",
  },
}));
