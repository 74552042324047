import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ErrorBoundaries from "../components/ErrorBoundaries";
import PageNotFound from "../components/PageNotFound";
import UnAuthorized from "../components/UnAuthorized";
import APP_ROUTE from "../constants/APP_ROUTE";
import ConfirmationPage from "../pages/ConfirmationPage";
import ConfirmationPageBBVA from "../pages/ConfirmationPageBBVA";
import ConfirmationPageBonarte from "../pages/ConfirmationPageBonarte";
import AccountCreated from "../pages/createAccount/AccountCreated.page";
import CreateAccount from "../pages/createAccount/CreateAccount.page";
import EmailVerification from "../pages/createAccount/EmailVerification.page";
import Registration from "../pages/createAccount/main";
import VerifyCode from "../pages/createAccount/VerifyCode.page";
import ForgotPassword from "../pages/ForgotPassword.page";
import Health from "../pages/Health.page";
import LoginPage from "../pages/Login.page";
import PasswordChanged from "../pages/PasswordChanged.page";
import ResetPasswordPage from "../pages/ResetPassword.page";
import ComponentLoader from "../utils/ComponentLoader";
import { getEnv } from "../utils/urls";
import SecureRouteabc from "./SecureRoute";

const Dashboard = lazy(() =>
  ComponentLoader(() => import("../pages/dashboard"))
);
const OpenInvoices = lazy(() =>
  ComponentLoader(() => import("../pages/dashboard/open-invoices/OpenInvoices"))
);
const InvoicesMain = lazy(() =>
  ComponentLoader(() => import("../pages/dashboard/open-invoices/InvoicesMain"))
);
const InvoiceDetail = lazy(() =>
  ComponentLoader(() =>
    import("../pages/dashboard/open-invoices/InvoiceDetail")
  )
);
const PaidInvoices = lazy(() =>
  ComponentLoader(() => import("../pages/dashboard/paid-invoices"))
);

const TestForm = lazy(() =>
  ComponentLoader(() => import("../pages/dashboard/CitibankTestForm"))
);

const PaymentSummary = lazy(() =>
  ComponentLoader(() =>
    import("../pages/dashboard/open-invoices/PaymentSummary")
  )
);

const Help = lazy(() => ComponentLoader(() => import("../pages/Help.page")));

const RootRouter = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const getEnvData = getEnv();

  const oktaAuth = new OktaAuth({
    clientId: getEnvData.oktaClientId,
    issuer: getEnvData.oktaIssuer,
    redirectUri: `${getEnvData.host}${getEnvData.callbackPath}`,
    scopes: ["openid", "email", "profile"], // Add Customer Number and Site ID to the "profile" scope.
  });

  const customAuthHandler = () => {
    navigate("/");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  return (
    <>
      <ErrorBoundaries>
        <Suspense fallback={"App Loading...."}>
          <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              <Route path={getEnvData.callbackPath} element={<LoginCallback />} />
              <Route path={APP_ROUTE.APP_ROOT} element={<LoginPage />} />
              <Route path={APP_ROUTE.APP_CREATE_ACCOUNT} element={<Registration />}>
                <Route index element={<CreateAccount />} />
                <Route path="verify-code" element={<VerifyCode />} />
                <Route path="step-2" element={<EmailVerification />} />
              </Route>
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPasswordPage />} />
              <Route path="account-created" element={<AccountCreated />} />
              <Route path="password-changed" element={<PasswordChanged />} />


              {/*==== Dashboard Routes START ====*/}
              <Route exact path={APP_ROUTE.APP_DASHBOARD}
                     element={
                       <SecureRouteabc>
                         <Dashboard {...props} />
                       </SecureRouteabc>
                     }
              >
                <Route path={APP_ROUTE.APP_DASHBOARD} element={<OpenInvoices />}>
                  <Route index element={<InvoicesMain />} />
                  <Route path={`${APP_ROUTE.APP_DASHBOARD}${APP_ROUTE.APP_OPEN_INVOICES_DETAIL}/:invoiceId`} element={<InvoiceDetail navigate={navigate} />} />
                  <Route path={`${APP_ROUTE.APP_DASHBOARD}${APP_ROUTE.APP_PAYMENT_SUMMARY}`} element={<PaymentSummary navigate={navigate} />} />
                </Route>
                <Route path={`${APP_ROUTE.APP_DASHBOARD}${APP_ROUTE.APP_PAID_INVOICES}`} element={<PaidInvoices />} />
                <Route path={`${APP_ROUTE.APP_DASHBOARD}${APP_ROUTE.APP_HELP}`} element={<Help />} />
                <Route path={`${APP_ROUTE.APP_DASHBOARD}${APP_ROUTE.APP_TESTFORMS}`} element={<TestForm />} />
              </Route>
              {/*==== Dashboard Routes END ====*/}

              <Route path="/403" element={<UnAuthorized {...props} navigate={navigate} location={location} />} />
              <Route exact path="/training/index.html" />
              <Route exact path="/health" element={<Health />} />
              <Route path="/confirmation" element={<ConfirmationPage {...props} />} />
              <Route path="/confirmation_bbva" element={<ConfirmationPageBBVA {...props} />} />
              <Route path="/confirmation_banorte" element={<ConfirmationPageBonarte {...props} />} />
              <Route path="/404" element={<PageNotFound {...props} navigate={navigate} />} />
              <Route path="/paymentconf/citibanamex" element={<ConfirmationPage />} />
              <Route path="*" element={<PageNotFound {...props} navigate={navigate} />} />
            </Routes>
          </Security>
        </Suspense>
      </ErrorBoundaries>
    </>
  );
};

export default RootRouter;
