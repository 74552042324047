import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${(props)=>props.imgUrl});
  background-color: #323758;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const CommonContainer = styled.div`
  width: 95%;
  height: fit-content;
  background: white;
  margin: auto;
  border-radius: 3px;
`;
