import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingHOC from "../../components/common/loader/LoadingHOC.component";
import { SignInButton, useStyles } from "../../components/login/Login.styles";
import { LocalContext } from "../../LocalProvider";
import { validateProfile } from "../../services/registration.services";

const CreateAccount = (props) => {
  const { setLoading } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { translate } = useContext(LocalContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  
  const [values, setValues] = useState({
    erpID: "",
    userId: "",
    firstName: "",
    lastName: "",
    userIdType: "Email"
  });

  useEffect(() => {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.userId) &&
      values.erpID.length > 5 &&
      values.firstName !== "" &&
      values.lastName !== ""
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const verifyProfile = () => {
    setLoading(true);
    const profileData = {
      erpID: values.erpID,
      userId: values.userId,
      userIdType: values.userIdType
    };
    validateProfile(profileData).then((res) => {
      setLoading(false);
      if (res.success) {
        navigate("/create-account/verify-code", {
          state: {
            userData: values,
          },
        });
      } else {
        setErrorMsg(translate(res.msg));
      }
    });
  };

  return (
    <Grid item md={12} xs={12} sx={{ height: "410px" }}>
      <form
        className={classes.root}
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <h2 className={classes.signInTitle}>
              {translate("Create an Account")}
            </h2>
          </Grid>
          {errorMsg && (
            <Grid item xs={12} sx={{ pt: "0 !important" }}>
              <br/>
              <strong style={{ 'margin-top': '10px !important' }} className={classes.passwordNotification}>
                {errorMsg}
              </strong>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "500" }}
              >
                {translate("Cutomer ID")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={values.erpID}
                label="Customer ID"
                onChange={handleChange("erpID")}
                className={classes.inputBox}
                type="text"
                autoFocus
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "500"  }}
              >
                {translate("Firstname")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={values.firstName}
                label="Firstname"
                onChange={handleChange("firstName")}
                className={classes.inputBox}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "500" }}
              >
                {translate("Lastname")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                value={values.lastName}
                label="Lastname"
                onChange={handleChange("lastName")}
                className={classes.inputBox}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.gridMargin}>
              <InputLabel
                value={values.userId}
                htmlFor="component-outlined"
                style={{ fontWeight: "500" }}
              >
                {translate("Email Address")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined"
                label="Email Address"
                onChange={handleChange("userId")}
                className={classes.inputBox}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SignInButton disabled={isDisable} onClick={verifyProfile}>
              {translate("Continue").toUpperCase()}
            </SignInButton>
          </Grid>

          <Grid item xs={12} className={classes.signUpLink}>
            <p>{translate("Already Registered?")}</p>
            <Button onClick={() => navigate("/")}>
              <strong>{translate("Sign In")}</strong>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

CreateAccount.propTypes = {};

export default LoadingHOC(CreateAccount);
