import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useStyles } from "./PasswordRestriction.styles";
import { LocalContext } from "../../../LocalProvider";

const PasswordRestriction = (props) => {
  const { username, firstname, lastname, password, setAllValid } = props;
  console.log('passwordRestriction password', password);
  console.log('passwordRestriction password', password.newPassword);

  const classes = useStyles();
  //const [password, setPassword] = useState(newPassword);
  const [containsMin, setContainsMin] = useState(false); // atleast 8 characters
  const [containsMax, setContainsMax] = useState(false); // max 15 characters
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [containNoUsername, setContainNoUsername] = useState(false); // not contain username, firstname & lastname
  const { translate } = useContext(LocalContext);

  useEffect(() => {
    validatePassword();
  }, [password]);

  // all validations passed
  useEffect(() => {
    if (
      containsMin &&
      containsMax &&
      containsUL &&
      containsLL &&
      containsN &&
      containsSC &&
      containNoUsername
    ) {
      setAllValid(true);
    } else setAllValid(false);
  }, [
    containsMin,
    containsMax,
    containsUL,
    containsLL,
    containsN,
    containsSC,
    containNoUsername,
  ]);

  // labels and state boolean corresponding to each validation
  const rules = [
    ["At least 8 characters", containsMin],
    ["Maximum 15 characters", containsMax],
    ["An uppercase letter (A-Z)", containsUL],
    ["A lowercase letter (a-z)", containsLL],
    ["A number (0-9)", containsN],
    ["A special character (!@#$)", containsSC],
    ["Not contains username, firstname, lastname", containNoUsername],
  ];

  const validatePassword = () => {
    // has 8 characters
    if (password.length >= 8) setContainsMin(true);
    else setContainsMin(false);
    // has 8 characters
    if (password.length > 0 && password.length <= 15) setContainsMax(true);
    else setContainsMax(false);
    // has uppercase letter
    if (password.toLowerCase() != password) setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    if (password.toUpperCase() != password) setContainsLL(true);
    else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);

    // not contains username
    if (username && firstname && lastname) {
      if (
        password.length > 0 &&
        !password.toLowerCase().includes(username.toLowerCase()) &&
        !password.toLowerCase().includes(firstname.toLowerCase()) &&
        !password.toLowerCase().includes(lastname.toLowerCase())
      ) {
        setContainNoUsername(true);
      }  else {
        setContainNoUsername(false);
      } 

    } else {
      setContainNoUsername(true);
    }
   
  };

  return (
    <Grid container className={classes.root} direction="column">
      <Typography>{translate("Must contain:")}</Typography>
      <List>
        {rules.map((data, key) => (
          <ListItem key={key}>
            {data[1] && (
              <ListItemIcon>
                <DoneIcon fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText
              primary={translate(data[0])}
              className={data[1] ? classes.listText : ""}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default PasswordRestriction;
