import { Divider, Grid, Typography, Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useContext, useState } from "react";
import styled from "styled-components";
import { LocalContext } from "../../LocalProvider";
import { Container } from "../styled/Container.styled";

const StretchedMain = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > .MuiPaper-root {
    border-radius: 0;
    background-color: #59b852;
    margin-bottom: 2.25rem;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.expand ? "40%" : "26%")};
  background: #fffeff;
  border-radius: ${(props) => (props.expand ? "0" : "15px")};
  margin: auto;
  padding: 20px;
  @media (min-width: 1920px) {
    max-width: 20%;
  }
  @media (max-width: 991px) {
    max-width: 40%;
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    "&>div:first-child": {
      color: "#545254",
      fontSize: "0.9rem",
    },
    "& > div.MuiGrid-direction-xs-column": {
      backgroundColor: "#f9f7f9",
      margin: "2rem 0 0 0",
      padding: "1.5rem",
      borderRadius: "8px",
      "& p:first-child": {
        color: "#999999",
      },
    },
    "& .MuiButton-root": {
      textTransform: "capitalize",
      fontWeight: 400,
      fontSize: "0.9rem",
      padding: "0",
      lineHeight: "1.5",
      letterSpacing: "0.00938em",
      color:"#0076B3"
    },
    "& hr.MuiDivider-root.MuiDivider-middle": {
      margin: "1.5rem 0 0.5rem 0",
      border: "0.5px solid #8b898b",
    },
  },
}));

const LoginLayout = (props) => {
  const { children, notification, handleClose } = props;
  const classes = useStyles();
  const { translate } = useContext(LocalContext);

  return (
    <Container imgUrl={'/icons/login-background.png'}>
      <div className="login-logo-container">
        <div className="img-container">
          <img src="/icons/logo-payment-portal.png" alt="logo" />
          <img src="/icons/logo-shared-services.png" alt="logo" />
        </div>
      </div>
      <StretchedMain>
        {notification && (
          <Alert icon={false} severity="success" onClose={handleClose}>
            {translate(notification)}
          </Alert>
        )}
        <StyledContainer expand={props.expand}>
          <Grid container className={classes.root}>
            {!props.expand && (
              <Grid item md={12} xs={12}>
                {translate("PepsiCo Mexico Payment Portal")}
              </Grid>
            )}
            {/* <Grid item md={12} xs={12}>
              <Divider variant="middle" />
            </Grid> */}
            {children}
            <Grid container sx={{padding: '1rem 0.5rem !important'}} direction="column">
              <Typography>{translate("Help?")}</Typography>
              <Typography sx={{whiteSpace:'nowrap'}}>
                {translate("SPA-DetallePago-CSC@pepsico.com")}
              </Typography>
            </Grid>
          </Grid>
        </StyledContainer>
      </StretchedMain>
    </Container>
  );
};

LoginLayout.propTypes = {};

export default LoginLayout;
