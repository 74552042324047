import React, {useContext, useState} from 'react'
import { Select } from '@mui/material'
import MenuItem from "@mui/material/MenuItem";
import styled from 'styled-components';
import { LocalContext } from '../../LocalProvider';

const StyledSelect = styled(Select)`
  & fieldset{
    border: none;
  }
`
function LanguageSelector() {
  
  // const [language, setLanguage] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "ES"); // Default selection will be ES
  const { selectedLocaleLang, handleLanguageChange } = useContext(LocalContext);
  const handleChange = (lang) => {
    handleLanguageChange(lang);
    // localStorage.setItem("language",lang);
    // window.location.reload(false);
  }

  return (
    <div>
        <StyledSelect
            id='languageDropDown'
            value={selectedLocaleLang}
            onChange={(e)=> handleChange(e.target.value)}
            autoWidth
            sx={{ color: '#535353', fontWeight: '600'}}
        >
           
            <MenuItem sx={{fontWeight: selectedLocaleLang==='ES'? 'bold':'', color: '#535353'}} value={'ES'}>ES</MenuItem>
            <MenuItem sx={{fontWeight: selectedLocaleLang==='EN'? 'bold':'', color: '#535353'}} value={'EN'}>EN</MenuItem>
        </StyledSelect>
    </div>
  )
}

export default LanguageSelector