import API_ROUTE from "../constants/API_ROUTE";
import { apiHeadersForAuthAPIs } from "../utils/urls";

const validateProfile = async (profileData) => {
  return await fetch(API_ROUTE.VALIDATE_PROFILE, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(profileData),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

const verifyOTP = async (profileData) => {
  return await fetch(API_ROUTE.VERIFY_CODE, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(profileData),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

const register = async (userData) => {
  // console.log(userData);
  return await fetch(API_ROUTE.REGISTER, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(userData),
  })
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
      return res;
    })
    .catch((err) => console.log(err));
};

const forgotPassword = async (emailObj) => {
  return await fetch(API_ROUTE.FORGOT_PASSWORD, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(emailObj),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

const setNewPassword = async (reqObj) => {
  // console.log('setNewPassword reqObj');
  // console.log(reqObj);
  return await fetch(API_ROUTE.RESET_PASSWORD, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(reqObj),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
};

const verifyRecovery = async (reqObj) => {
  // console.log(reqObj);
  return await fetch(API_ROUTE.VERIFY_RECOVERY, {
    method: "POST",
    headers: apiHeadersForAuthAPIs,
    body: JSON.stringify(reqObj),
  })
  .then((res) => res.json())
  .then((res) => {
    // console.log(res);
    return res;
  })
  .catch((err) => console.log(err));
}

export { validateProfile, verifyOTP, register, forgotPassword, setNewPassword, verifyRecovery};
