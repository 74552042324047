import { CssBaseline } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginLayout from "../components/layouts/LoginLayout";
import Login from "../components/login/Login.component";

const LoginPage = () => {
  const { state } = useLocation();
  const [msg, setMsg] = useState("");
  useEffect(() => {
    if (state?.msg) {
      setMsg(state.msg);
      window.history.replaceState({...window.history.state,usr:""}, document.title);
    } 
  },[]);

  const handleClose = ()=>{
    setMsg("");
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout notification={msg} handleClose ={handleClose}>
        <Login />
      </LoginLayout>
    </React.Fragment>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
