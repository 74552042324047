import { useContext } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { colors } from "../../../styles/variables";
import { LocalContext } from "../../../LocalProvider";

const loaderAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;
const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.transparantBg};
  color: ${colors.black};
  z-index: 100;
  flex-direction: column-reverse;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: ${colors.lightBlue} transparent ${colors.lightBlue}
      transparent;
    animation: ${loaderAnimation} 1.2s linear infinite;
  }
`;

const Loading = (props) => {
  const { translate } = useContext(LocalContext);
  return <LoadingWrapper>{translate("")}...</LoadingWrapper>;
};

Loading.propTypes = {};

export default Loading;
