import { useState } from "react";
import Loading from "./Loading.component";

const LoadingHOC = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setIsLoading] = useState(false);

    const setIsLoadingState = (loadingStatus) => {
      setIsLoading(loadingStatus);
    };

    return (
      <>
        {isLoading && <Loading />}
        <WrappedComponent {...props} setLoading={setIsLoadingState} />
      </>
    );
  }
  return HOC;
};

export default LoadingHOC;
