
import { useOktaAuth } from "@okta/okta-react";
import React, { createContext, useEffect,useState} from "react";
import {customerDetail} from '../services/customer.service';
import ErrorDialog from "../components/reusable/ErrorDialog";

const CustomerContext = createContext({});



const RequireAuth = ({ children }) => {
  const { authState } = useOktaAuth();
  const [customer, setCustomer] = useState('');
  const [showDailog,setShowDialog] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');
  const { oktaAuth } = useOktaAuth();
  const accessToken = localStorage.getItem("accessToken");
  useEffect(()=>{
    if (authState?.isAuthenticated) {
      localStorage.setItem("accessToken", authState.accessToken.accessToken);
      const customerInfo = JSON.parse(localStorage.getItem("currUser"));
      if (!customerInfo?.customer_number) {
        customerDetail(authState.idToken.claims.sub).then((userdetail) => {
          if (userdetail && userdetail.userDetails) {
            const userInfo = {
              userId: userdetail.userDetails.userID,
              name: userdetail.userDetails.CustomerName,
              customer_number: userdetail.userDetails.customerId,
              email: userdetail.userDetails.userID,
            };
            localStorage.setItem("currUser", JSON.stringify(userInfo));

            setCustomer(userInfo);
          }
        }).catch((err) => {
          console.log(err)
          handleDialog();
          setErrorMessage(JSON.stringify(err));
        });
      }
      else setCustomer(customerInfo);
      deleteAllCookies();
    }
     
  },[authState])

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");
    console.log(cookies);
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;  path=/;";
    }
  }

  const handleDialog = () => setShowDialog(true);

  const handleClose = () => signOut();

  const signOut = async () => {
    setShowDialog(false);
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + "/",
    });
      localStorage.clear();
  };
  // restrict secure component to render for unauthenticated user
  if (!accessToken) {
    // if (!authState?.isPending) {
       window.location.replace("/");
    // }
    return null;
  }

  return (<CustomerContext.Provider value={{ customer }}><React.Fragment>{children}</React.Fragment>
    <ErrorDialog showDailog={showDailog} closeDialog={handleClose} errorMessage={errorMessage} errorTitle={'Token Expired'} ></ErrorDialog>
  </CustomerContext.Provider>);
};



const SecureRouteabc = ({ children }) => {
  return <RequireAuth>{children}</RequireAuth>;
};

export default SecureRouteabc;
export {CustomerContext} 