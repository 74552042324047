import { FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LocalContext } from "../../LocalProvider";
import { register, setNewPassword } from "../../services/registration.services";
import PasswordRestriction from "../common/passwordRestriction/PasswordRestriction.component";
import { SignInButton, useStyles } from "../login/Login.styles";
import { useParams } from 'react-router-dom';

const ResetPassword = (props) => {
  const { state } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { translate } = useContext(LocalContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  // checks all password validations are true
  const [allValid, setAllValid] = useState(false);
  const [token, setToken] = useState('');
  const [values, setValues] = useState({
    confirmPassword: "",
    password: "",
    showPassword: false,
  });

  const userData = state?.userData;
  const searchParams = new URLSearchParams(window.location.search);
  

  useEffect(() => {
    if (values.password === values.confirmPassword && allValid) {
      setIsDisable(false);
    }
  }, [values.confirmPassword]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const resetPassword = () => {
    let msg = "Your password was successfully reset.";
    if(values.confirmPassword && (values.password !== values.confirmPassword)){
      setErrorMsg(translate("Password doesn't match."));
      return;
    }else if(values.password === values.confirmPassword){
      setErrorMsg("");
    }
    if(!searchParams.get("token")){
      const registerationData = {
        profile: {
          userId: userData.userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.userId,
        },
        credentials: {
          password: { value: values.password },
        },
      };
      register(registerationData).then((res) => {
        if (res && res.success) {
          if (state?.purpose === "account") {
            msg = "Your account was successfully created.";
          }
          navigate("/", {
            state: {
              msg: msg,
            },
          });
        }else{
          setErrorMsg("Something went wrong. Please make sure your password does not contain your first name, last name, or part of your email address.");

          // if(res.errorSummary){
          //   setErrorMsg(res.errorSummary);
          // }
        }

      });
    }
    else{
      const reqPayload = {
        stateToken: searchParams.get("token"),
        newPassword:  values.password
      }
      setNewPassword(reqPayload).then(resp=>{
        if(resp.status && resp.status === 'SUCCESS'){
          navigate("/", {
            state: {
              msg: msg,
            },
          });
        }else{
          if(resp.errorSummary){
            setErrorMsg(translate(resp.errorSummary));
          }
        }
      })
    }
  };

  return (
    <Grid item md={12} xs={12} sx={{ height: "410px" }}>
      <form
        className={classes.root}
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Grid container spacing={2}>
          <Grid item md={10} xs={10}>
            <h2 style={{lineHeight:'1em'}}>{translate("Set Password")}</h2>
          </Grid>
          <Grid item xs={12}>
            {errorMsg && (
              <strong className={classes.passwordNotification}>{translate(errorMsg)}<br/></strong>
            
            )}
            <FormControl variant="outlined" className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "bold" }}
              >
                {translate("Password")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined-password"
                value={values.password}
                label="Password"
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                className={classes.inputBox}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ pt: "0px !important" }}>
            <PasswordRestriction
              password={values.password}
              username={userData?.userId}
              firstname={userData?.firstName}
              lastname={userData?.lastName}
              setAllValid={setAllValid}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.gridMargin}>
              <InputLabel
                htmlFor="component-outlined"
                style={{ fontWeight: "bold" }}
              >
                {translate("Confirm Password")}
              </InputLabel>
              <OutlinedInput
                id="component-outlined-password"
                value={values.confirmPassword}
                label="Confirm Password"
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("confirmPassword")}
                className={classes.inputBox}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography sx={{ fontSize: "0.9rem", color: "#757575" }}>
              Must be at least 9 digits. Must contain at least one lowercase
              letter, one uppercase letter and one number.
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <SignInButton disabled={isDisable} onClick={resetPassword}>
              {translate("Continue").toUpperCase()}
            </SignInButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
