import styled from "styled-components";

const StretchedMain = styled.main`
    flex: 1;
    display:flex;
    flex-direction:column;
    margin: 1rem;
    @media (min-width: 991px) {
        margin:0rem 0rem 1rem 0rem;
    }
    @media (max-width:600px){
        margin:0rem;      
    }
`;

const Layout = ({ children }) => {

   return (
      <StretchedMain>{children}</StretchedMain>
   );

};

Layout.propTypes = {};

export default Layout;
