const APP_ROOT = process.env.PUBLIC_URL;
const APP_DASHBOARD = APP_ROOT + "/dashboard";
const APP_OPEN_INVOICES = APP_DASHBOARD + "/open-invoices";
const APP_PAID_INVOICES = APP_ROOT + "paid-invoices";
const APP_TESTFORMS = APP_ROOT + "citibank-redirect-test"; /* This is temporary form for testing */
const APP_OPEN_INVOICES_DETAIL = "/invoice-detail";
const APP_PAYMENT_SUMMARY = "/summary";
const APP_CREATE_ACCOUNT = APP_ROOT + "create-account";
const APP_CONFIRMATION = APP_ROOT + "confirmation";
const APP_TABLE = APP_ROOT + "TABLE";
const APP_HELP = APP_ROOT + "help";
// login and register api cal
const APP_LOGIN = APP_ROOT + "login";

// TO ENABLE/DISABLE TEST PAYMENT
const APP_TEST_MODE = false;

const APP_ROUTE = {
  APP_ROOT,
  APP_DASHBOARD,
  APP_OPEN_INVOICES,
  APP_PAID_INVOICES,
  APP_TESTFORMS,
  APP_OPEN_INVOICES_DETAIL,
  APP_PAYMENT_SUMMARY,
  APP_LOGIN,
  APP_CREATE_ACCOUNT,
  APP_TABLE,
  APP_HELP,
  APP_TEST_MODE,
};

export default APP_ROUTE;
