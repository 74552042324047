import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-root": {
      fontSize: "0.6rem",
    },
    "& .MuiList-root, li.MuiListItem-root": {
      padding: "0",
      margin: "0",
      "& .MuiListItemIcon-root": {
        minWidth: "0.8rem",
        "& .MuiSvgIcon-root": {
          width: "0.5em",
          height: "0.5em",
          color: "#299D49",
        },
      },
      "& .MuiListItemText-root": {
        color: "red",
      },
    },
    "& li.MuiListItem-root > .MuiListItemText-root": {
      margin: "0",
    },
  },
  listText: {
    color: "#299D49 !important",
  },
}));
