import styled from "styled-components";
import { breakpoints, mediaQueries } from "../../styles/variables";
const SingleColumn = styled.section`
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  @media ${mediaQueries.mobileAndAbove} {
    max-width: ${(props) => (props.fullWidth ? "100%" : breakpoints.container)};
    padding: 0.5rem;
    max-width: 100%;
  }
  @media ${mediaQueries.container} {
    max-width: 95%;
  }
  @media ${mediaQueries.belowContainerStandard} {
    // max-width: 100%;
  }
  @media ${mediaQueries.belowiPad} {
    max-width: 100%;
    // padding: 0.5rem;
  }
  @media (min-width: 1921px) {
    max-width: ${breakpoints.container};
  }
`;

export default SingleColumn;
