import API_ROUTE from "../constants/API_ROUTE";
import { apiHeaderData } from "../utils/urls";

const customerDetail = async (oktaid) => {
    const token  =  localStorage.getItem("accessToken");
    if(!apiHeaderData['okta-accesstoken']){
        apiHeaderData['okta-accesstoken'] = token;
    }
    return await fetch(API_ROUTE.GET_CUSTOMER_DETAIL + "?oktaId=" + oktaid, {
        method: "GET",
        headers: apiHeaderData,
    })
        .then((res) => res.json())
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export { customerDetail };