import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext} from "react";
import { LocalContext } from "../../LocalProvider";

const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #ccc',
    boxShadow: 24,
    p: 4,
    backgroundColor: '#fff'
  };

export default function ErrorDialog(props){
    const {showDailog,closeDialog,errorMessage, errorTitle }  = props;
    const {translate} = useContext(LocalContext);
  
    return (
      <Modal
      open={showDailog}
      onClose={closeDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Modalstyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{fontWeight: '550'}}>
          {translate(errorTitle)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {translate(errorMessage)}
        </Typography>
        <br />
        <Button onClick={closeDialog} color="primary" autoFocus>
          {translate('Continue')}
        </Button>
      </Box>
    </Modal>
    )
  }