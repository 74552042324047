export function getEnv() {
  if (process.env.REACT_APP_HOST_URL_LOCAL.includes(window.location.hostname)) {
    // LOCAL env details
    return {
      appName: process.env.REACT_APP_APP_NAME,
      siteId: process.env.REACT_APP_SITE_ID,
      callbackPath: process.env.REACT_APP_CALLBACK_PATH,
      host: process.env.REACT_APP_HOST_URL_LOCAL,
      url: process.env.REACT_APP_API_LOCAL,
      oktaIssuer: process.env.REACT_APP_ISSUER_LOCAL,
      oktaClientId: process.env.REACT_APP_CLIENT_ID_LOCAL,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      xApiKey: process.env.REACT_APP_X_API_KEY_QA
    };
  } else if (
    process.env.REACT_APP_HOST_URL_DEV.includes(window.location.hostname)
  ) {
    // DEV env details
    console.log("---- - dev", process.env.REACT_APP_HOST_URL_DEV);

    return {
      appName: process.env.REACT_APP_APP_NAME,
      siteId: process.env.REACT_APP_SITE_ID,
      callbackPath: process.env.REACT_APP_CALLBACK_PATH,
      host: process.env.REACT_APP_HOST_URL_DEV,
      url: process.env.REACT_APP_API_DEV,
      oktaIssuer: process.env.REACT_APP_ISSUER_DEV,
      oktaClientId: process.env.REACT_APP_CLIENT_ID_DEV,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      xApiKey: process.env.REACT_APP_X_API_KEY_QA
    };
  } else if (
    process.env.REACT_APP_HOST_URL_QA.includes(window.location.hostname)
  ) {
    console.log("---- - QA", process.env.REACT_APP_HOST_URL_QA);
    // PROD env details
    return {
      appName: process.env.REACT_APP_APP_NAME,
      siteId: process.env.REACT_APP_SITE_ID,
      callbackPath: process.env.REACT_APP_CALLBACK_PATH,
      host: process.env.REACT_APP_HOST_URL_QA,
      url: process.env.REACT_APP_API_QA,
      oktaIssuer: process.env.REACT_APP_ISSUER_QA,
      oktaClientId: process.env.REACT_APP_CLIENT_ID_QA,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      xApiKey: process.env.REACT_APP_X_API_KEY_QA
    };
  } else if (
    process.env.REACT_APP_HOST_URL_PROD.includes(window.location.hostname)
  ) {
    console.log("---- - Prod", process.env.REACT_APP_HOST_URL_PROD);
    // PROD env details
    return {
      appName: process.env.REACT_APP_APP_NAME,
      siteId: process.env.REACT_APP_SITE_ID,
      callbackPath: process.env.REACT_APP_CALLBACK_PATH,
      host: process.env.REACT_APP_HOST_URL_PROD,
      url: process.env.REACT_APP_API_PROD,
      oktaIssuer: process.env.REACT_APP_ISSUER_PROD,
      oktaClientId: process.env.REACT_APP_CLIENT_ID_PROD,
      oktaDisableHttpsCheck: true,
      oktaUseInteractionCode: true,
      xApiKey: process.env.REACT_APP_X_API_KEY_PROD
    };
  }

  console.log("---- - DEFAULT");

  return "";
}

// API defualt headers
export const apiHeaderData = {
  "Content-Type": "application/json",
  "okta-accesstoken": localStorage.getItem("accessToken"),
  siteId: getEnv().siteId,
};

export const apiHeadersForAuthAPIs = {
  "Content-Type": "application/json",
  siteId: getEnv().siteId,
  "x-api-key": getEnv().xApiKey
}
