import { CssBaseline } from "@mui/material";
import React from "react";
import LoginLayout from "../components/layouts/LoginLayout";
import PasswordChangedComponent from "../components/resetPassword/PasswordChanged.component";

const PasswordChanged = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout expand>
        <PasswordChangedComponent />
      </LoginLayout>
    </React.Fragment>
  );
};

export default PasswordChanged;
