import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalContext } from "../../LocalProvider";
import { SignInButton, useStyles, WhiteButton } from "./Login.styles";
import LoadingHOC from "../../components/common/loader/LoadingHOC.component";
import { forgotPassword, verifyRecovery, setNewPassword, register } from "../../services/registration.services";
import PasswordRestriction from "../common/passwordRestriction/PasswordRestriction.component";

const ForgotPasswordComponent = (props) => {
  const { setLoading } = props;
  const { translate } = useContext(LocalContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const navigate = useNavigate();
  const classes = useStyles();
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    userIdType: "Email"
  });

  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [recoveryOtp, setRecoveryOtp] = useState(null);
  const [recoveryToken, setRecoveryToken] = useState(null);
  const [recoveryCodeInputVal, setRecoveryCodeInputVal] = useState("");
  const [stateToken, setStateToken] = useState(null);

  // user data
  const [userData, setUserData] = useState(null);

  // new password reset values
  const [newAccountPassword, setNewAccountPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [allValid, setAllValid] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  };

  const verifyProfile = (e) => {
    // console.log('---verifyProfile---')
    e.preventDefault();
    setLoading(true);
    const emailObj = {
      username: values.email,
      userIdType: values.userIdType
    };
    forgotPassword(emailObj).then((res) => {
      // console.log('forgotPassword res', res );
      setLoading(false);
      if (res.success === false) {
        setErrorMsg(translate('Invalid email'));
        return
      }
      setRecoveryOtp(res.recoveryOtp);
      setRecoveryToken(res.recoveryToken);
      setForgotPasswordStep(2);
      setErrorMsg("");
    });
  };

  const verifyForgotPasswordCode = (e) => {
    e.preventDefault();
    setLoading(true);
    const reqObj = {
      username: values.email,
      recoveryOtp: recoveryCodeInputVal,
      code: recoveryCodeInputVal,
      recoveryToken: recoveryToken,
    };
    verifyRecovery(reqObj).then((res) => {
      setLoading(false);
      if(res.success === false){
        setErrorMsg("Invalid recovery code");
        return
      }
      // console.log('res');
      // console.log(res);
      setUserData(res._embedded.user);
      setStateToken(res.stateToken);
      setForgotPasswordStep(3)
      setErrorMsg("");
    })
  }

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    let msg = "Your password was successfully reset.";
    if(newPasswordConfirm && (newAccountPassword !== newPasswordConfirm)){
      setErrorMsg(translate("Password doesn't match."));
      setLoading(false);      
      return
    } else if (newAccountPassword === newPasswordConfirm){
      setErrorMsg("");
    }


    const reqPayload = {
      stateToken: stateToken,
      newPassword: newPasswordConfirm,
      username: values.email,
    }

    setNewPassword(reqPayload).then((resp) => {
      // console.log('resp');
      // console.log(resp);
      if(resp.status && resp.status === 'SUCCESS'){
        navigate("/", {
          state: {
            msg: msg,
          },
        });
      }else{
        if(resp.errorSummary){
          setErrorMsg(translate(resp.errorSummary));
          setLoading(false);
        }
      }
    });
  }

  

  useEffect(() => {
    if (values.email.length > 2 && values.password.length > 2) {
      setIsDisable(false);
    }
  }, [values]);

  if(forgotPasswordStep === 1){
    return (
      <Grid item md={12} xs={12} sx={{ height: "410px" }}>
        <form className={classes.root} noValidate>
          <Grid container>

            <Grid item md={12} xs={12} paddingBottom={1}>
              <h2 className={classes.signInTitle}>
                {translate("Forgot Password")}
              </h2>
            </Grid>

            <Grid item xs={12} sx={{ mb: "2rem" }}>
              <Typography>
                {translate( `Enter the email address associated with your account and we’ll send you instructions to reset your password.`)}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: "1rem" }}>
              {errorMsg && (
                <strong className={classes.passwordNotification}>{errorMsg}</strong>
              )}
              <FormControl className={classes.gridMargin}>
                <InputLabel htmlFor="component-outlined" style={{ fontWeight: "bold" }}>
                  {translate("Email Address")}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  value={values.email}
                  label="Email Address"
                  onChange={handleChange("email")}
                  className={classes.inputBox}
                  type="email"
                  autoFocus
                />
              </FormControl>
            </Grid>
  
            <Grid item xs={12}>
              <SignInButton className={classes.gridMargin} disabled={isDisable} onClick={verifyProfile}>
                {translate("Send Email")}
              </SignInButton>
            </Grid>

            <Grid item xs={12} md={12}>
              <Divider sx={{ m: 0, border: "none !important" }}>
                {translate("or")}
              </Divider>
            </Grid>

            <Grid item xs={12}>
              <WhiteButton className={classes.gridMargin} variant="outlined" onClick={() => navigate("/")} >
                <strong>{translate("Go Back")}</strong>
              </WhiteButton>
            </Grid>

          </Grid>
        </form>
      </Grid>
    );
  } else if(forgotPasswordStep === 2){

    return (
      <Grid item md={12} xs={12} sx={{ height: "410px" }}>
        <form className={classes.root} noValidate>
          <Grid container>

            <Grid item md={12} xs={12} paddingBottom={1}>
              <h2 className={classes.signInTitle}>
                {translate("OTP")}
              </h2>
            </Grid>

            <Grid item xs={12} sx={{ mb: "2rem" }}>
              <Typography>
                {translate('Enter the six digit code you received in your email.')}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: "1rem" }}>
              {errorMsg && (
                <strong className={classes.passwordNotification}>{errorMsg}</strong>
              )}
              <FormControl className={classes.gridMargin}>
                <InputLabel
                  htmlFor="component-outlined"
                  style={{ fontWeight: "bold" }}
                >
                  {translate("OTP")}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  value={recoveryCodeInputVal}
                  label="Code"
                  onChange={(e) => {
                    setRecoveryCodeInputVal(e.target.value)
                  }}
                  className={classes.inputBox}
                  type="text"
                  autoFocus
                />
              </FormControl>
            </Grid>
  
            <Grid item xs={12}>
              <SignInButton
               className={classes.gridMargin}
                // disabled={isDisable} 
                onClick={verifyForgotPasswordCode}>
                {translate("Continue")}
              </SignInButton>
            </Grid>
            
          </Grid>
        </form>
      </Grid>
    );
  } else if(forgotPasswordStep === 3) {
    return (
      <Grid item md={12} xs={12} sx={{ height: "410px" }}>
        <form className={classes.root} noValidate>
          <Grid container>

            <Grid item md={12} xs={12} paddingBottom={1}>
              <h2 className={classes.signInTitle}>
                {translate("Reset Password")}
              </h2>
            </Grid>

            {/* <Grid item xs={12} sx={{ mb: "2rem" }}>
              <Typography>
                {translate(
                  `Enter the email address associated with your account and we’ll send you instructions to reset your password.`
                )}
              </Typography>
            </Grid> */}

            <Grid item xs={12} sx={{ mb: "1rem" }}>
              {errorMsg && ( <strong className={classes.passwordNotification}>{errorMsg}</strong>)}
                <FormControl variant="outlined" className={classes.gridMargin}>
                  <InputLabel htmlFor="component-outlined" style={{ fontWeight: "bold" }}>
                    {translate("Password")}
                  </InputLabel>
                  <OutlinedInput
                    id="component-outlined-password"
                    value={newAccountPassword}
                    label={translate("Password")}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      setNewAccountPassword(e.target.value)
                    }}
                    className={classes.inputBox}
                  />
                </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ pt: "0px !important" }}>
              <PasswordRestriction
                password={newAccountPassword}
                username={userData?.id}
                firstname={userData?.profile.firstName}
                lastname={userData?.profile.lastName}
                setAllValid={setAllValid}
              />
            </Grid>

            <Grid item xs={12} paddingTop={2}>
              <FormControl variant="outlined" className={classes.gridMargin}>
                <InputLabel htmlFor="component-outlined" style={{ fontWeight: "bold" }}>
                  {translate("Confirm Password")}
                </InputLabel>
                <OutlinedInput
                  id="component-outlined-password"
                  value={newPasswordConfirm}
                  label={translate("Confirm Password")}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    setNewPasswordConfirm(e.target.value)
                  }}
                  className={classes.inputBox}
                />
              </FormControl>
            </Grid>
  
            <Grid item xs={12}>
              <SignInButton
               className={classes.gridMargin}
                // disabled={isDisable} 
                onClick={resetPassword}>
                {translate("Confirm")}
              </SignInButton>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <Divider sx={{ m: 0, border: "none !important" }}>
                {translate("or")}
              </Divider>
            </Grid> */}

            {/* <Grid item xs={12}>
              <WhiteButton className={classes.gridMargin} variant="outlined" onClick={() => navigate("/")} >
                <strong>{translate("Go Back")}</strong>
              </WhiteButton>
            </Grid> */}

          </Grid>
        </form>
      </Grid>
    );
  } else {
    return null
  }


};

ForgotPasswordComponent.propTypes = {};

export default LoadingHOC(ForgotPasswordComponent);
