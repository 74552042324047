import { getEnv } from "../utils/urls";

let envData = getEnv();
const API_ROOT = envData && envData.url;
const API_VERSION = "/api/v0"; // this is NG anc CN
const API_VERSION_1 = "/api/v1";
const OKTA_API_ROOT = envData?.oktaApiUrl;
const GET_INVOICES = API_ROOT + API_VERSION + "/invoices";
const GET_BANKLIST = API_ROOT + API_VERSION + "/bank";
const POST_INVOICES = API_ROOT + API_VERSION + "/invoices";
const UPDATE_TRANSACTION = API_ROOT + API_VERSION + "/invoiceTransaction";
const GET_TRASLATIONLIST = API_ROOT + API_VERSION + "/translations";
const GET_CUSTOMER_DETAIL = API_ROOT + API_VERSION + "/get-customer-by-oktaid";
// register api

const VERIFY_RECOVERY = API_ROOT + API_VERSION_1 + "/auth/forgotpwd/verifyRecovery";

const VALIDATE_PROFILE = API_ROOT + API_VERSION_1 + "/auth/validateProfile";
const VERIFY_CODE = API_ROOT + API_VERSION_1 + "/auth/verifyCode";
const REGISTER = API_ROOT + API_VERSION_1 + "/auth";
const FORGOT_PASSWORD = API_ROOT + API_VERSION_1 + "/auth/forgotpwd";
const RESET_PASSWORD = API_ROOT + API_VERSION_1 + "/auth/credentials/reset_password";

const API_ROUTE = {
  API_ROOT,
  GET_INVOICES,
  GET_BANKLIST,
  POST_INVOICES,
  UPDATE_TRANSACTION,
  GET_TRASLATIONLIST,
  VALIDATE_PROFILE,
  VERIFY_CODE,
  VERIFY_RECOVERY,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_CUSTOMER_DETAIL,
};

export default API_ROUTE;
