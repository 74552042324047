import { CssBaseline } from "@mui/material";
import React from "react";
import LoginLayout from "../../components/layouts/LoginLayout";
import AccountCreatedComponent from "../../components/signUp/AccountCreated.component";

const AccountCreated = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <LoginLayout expand>
        <AccountCreatedComponent />
      </LoginLayout>
    </React.Fragment>
  );
};

export default AccountCreated;
